import clsx from 'clsx';
import React from 'react';

import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    width: '-moz-available',
    width: '-webkit-fill-available',
    width: 'fill-available',
    height: '100%',
    height: '-moz-available',
    height: '-webkit-fill-available',
    height: 'fill-available',
    backgroundColor: 'rgb(255,255,255,0.7)',
    borderRadius: 6,
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  progressSmall: {
    width: '20px !important',
    height: '20px !important',
  },
  progressBig: {
    width: '60px !important',
    height: '60px !important',
  },
  text: {
    marginTop: theme.spacing(2),
  },
}));

export default function LoadingOverlay(props) {
  const classes = useStyles();
  const { IsLoading, Size, className, text } = props;

  return (
    <>
      {IsLoading ? (
        <div className={clsx(classes.root, className)}>
          <div className={classes.container}>
            <CircularProgress className={clsx({ [classes.progressSmall]: Size === 'small', [classes.progressBig]: Size === 'big' })} />
            {text && <Box className={classes.text}>{text}</Box>}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
