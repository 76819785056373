import React, { useRef } from 'react';

import { TextField } from '@material-ui/core';

import useDelayChangedText from 'src/hooks/useDelayChangedText';

export default function DelayChangedTextField2(props) {
  const { onDelayValueChange, onValueChange, value, ...remains } = props;

  const { handleValueTextChange, handleOnKeyPress } = useDelayChangedText(onDelayValueChange, onValueChange);
  
  return <TextField {...remains} value={value} onChange={handleValueTextChange} onKeyPress={handleOnKeyPress} />;
}
